import cookie from '../plugins/jquery.cookie.js';
import Headroom from '../plugins/headroom.js';
import slick from '../plugins/slick.js';

jQuery(function($) {
  // Sticky Header
  var myElement = document.querySelector(".global-header");
  var headroom  = new Headroom(myElement, {
    "offset": 150,
    "tolerance": 0
  });
  headroom.init();

  // Home Hero Height
  if ($('.home-hero').length) {
    $('.home-hero').css('height', $(window).height());
  }

  // Project slider
  $('.project-slider').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: false,
    autoplay: false,
    slidesToShow: 1,
    variableWidth: true,
    prevArrow: '<svg class="prev" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" transform="matrix(-1 0 0 1 50 0)" fill="white"/><path d="M31 13L19 25L31 37" stroke="#565000" stroke-width="2" stroke-miterlimit="10"/></svg>',
    nextArrow: '<svg class="next" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" fill="white"/><path d="M19 13L31 25L19 37" stroke="#565000" stroke-width="2" stroke-miterlimit="10"/></svg>',
    adaptiveHeight: false
  });

  // Projects
  function removeURLParameter(url, parameter) {
    var urlparts = url.split('?');   
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);
      for (var i = pars.length; i-- > 0;) {   
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
          pars.splice(i, 1);
        }
      }
      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }
  $('.project-filter').on('click', function(){
    $('.project-filter').removeClass('active');
    $(this).addClass('active');
    $('#ajax-projects').addClass('fadeout').load('/projects?category=' + $(this).data('cat') + ' #ajax-projects>*', function(){
      $(this).removeClass('fadeout');
    });
    var $filterurl = removeURLParameter(window.location.href,'category') + "?category=" + $(this).data('cat');
    window.history.pushState({},"", $filterurl);
  });

  // Light Toggle
  $('.light-toggle').on('click', function(){
    $(this).toggleClass('on');
    $('body').toggleClass('darkMode');
    if ($('body').hasClass('darkMode')) {
      $.cookie('darkmode', 'on', { path: '/' });
    } else {
      $.cookie('darkmode', 'off', { path: '/' });
    }
  })

  // Status Bar
  $('#status-close').on('click', function(){
    $('.status-bar').css('display', 'none');
    $.cookie('status', $('.status-bar').attr('id'), { path: '/' });
  });

  // Pagination
  $('#pagination').on('click', 'a', function(e){
    e.preventDefault();
    $(this).remove();
    $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
      $('#ajax-posts').append($('#ajax-loader').html());
    });
    $('#pagination').load($(this).attr('href') + ' #pagination>*');
  });

  // Anchor
  $('a').on('click', function(e){
    var $href = $(this).attr('href');
    if ($href.match('^#')) {
      e.preventDefault();
      $('html, body').stop().animate({
          scrollTop: $($href).offset().top
      });
    }
  });

  // Video popups
  $('a').on('click', function(e){
    var $target = $(this).attr('href');
    if ($target.toLowerCase().indexOf("vimeo") >= 0) {
      e.preventDefault();
      var $n = $target.lastIndexOf('/');
      var $vid = $target.substring($n + 1);
      $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
    if ($target.toLowerCase().indexOf("youtu") >= 0) {
      e.preventDefault();
      var $y = $target.lastIndexOf('=');
      var $yid = $target.substring($y + 1);
      $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
  });
  $('.close-popup').on('click', function(){
    $('.video-popup-wrapper').removeClass('active');
    $('.popup-content').html('');
    $('body, html').removeClass('body-noscroll');
  });

  // Mobile Nav
  $('#mobile-nav-toggle').on('click', function(){
    var $navheight = $('#mobile-navigation').height() + 170;
    $('body, html').toggleClass('nav-locked');
    $('#mobile-navigation-wrapper').toggleClass('active');
    $(this).toggleClass('active');
  });

  // Resize for Match-Height and Slick-Slider glitch fix
  scroll_actions();
  $(window).bind('load', function(){
    $(window).trigger('resize');
    setTimeout(function(){ $(window).trigger('resize'); }, 500);
    scroll_actions();
  });
  $(document).ready(scroll_actions());
  $(window).scroll(scroll_actions());

  function scroll_actions() {
    $('.loading').each(function(){
      $(this).addClass('loaded');
    });
    $('.animate').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        $this.addClass('visible');
      }
    });
    $('.animate-1').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 100);
      }
    });
    $('.animate-2').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 200);
      }
    });
    $('.animate-3').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 300);
      }
    });
    $('.animate-4').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 400);
      }
    });
    $('.animate-5').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 500);
      }
    });
    $('.animate-6').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 600);
      }
    });
    $('.animate-7').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 700);
      }
    });
    $('.animate-8').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 800);
      }
    });
    $('.animate-9').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 900);
      }
    });
    $('.animate-10').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1000);
      }
    });
    $('.animate-11').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1100);
      }
    });
    $('.animate-12').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1200);
      }
    });
    $('.animate-13').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1300);
      }
    });
    $('.animate-14').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1400);
      }
    });
    $('.animate-15').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1500);
      }
    });
    $('.animate-16').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1600);
      }
    });
    $('.animate-17').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1700);
      }
    });
    $('.animate-18').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1800);
      }
    });
    $('.animate-19').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1900);
      }
    });
    $('.animate-20').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2000);
      }
    });
    $('.animate-21').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2100);
      }
    });
  }

});